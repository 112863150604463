import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Container } from 'react-bootstrap';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function App() {
  const location = useLocation();
  const videoID = new URLSearchParams(location.search).get('videoID');

  useEffect(() => {
    // Do something with the videoID
    console.log(videoID);
  }, [videoID]);

  return (
    <Container>
      <div className="App">
        <div class="ratio ratio-16x9">
          <iframe src={`https://www.youtube.com/embed/${videoID}?rel=0`}
           title="YouTube video" allowFullScreen>
           </iframe>
        </div>
      </div>
    </Container>
  );
}

export default App;
